import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    isVisible && (
      <div id="back-to-top">
        <ScrollLink
          to="top"
          smooth={true}
          duration={200}
          id="back-to-top-content"
          className="bg-pink position-relative align-items-center rounded-circle d-block"
        >
        </ScrollLink>
      </div>
    )
  );
};

export default BackToTop;
