import React, { useEffect, useRef, useState } from "react";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import { Link } from "react-router-dom";
import CanvasImageOverlay from "./CanvasTicket";
import Medias from "../../inc/Medias";

export default function FormTicket({ plan, setPlan }) {
  // STEPPERS
  const stepperRef = useRef(null);
  const [stepperInstance, setStepperInstance] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false); // État pour contrôler l'initialisation
  /**
   * CART OPERATION
   */
  const dbValues = [
    { type: "Solo", price: 15000 },
    { type: "Couple", price: 28000 },
    { type: "Groupe", price: 112000 },
  ];
  const findType = (inputPrice) => {
    const result = dbValues.find((item) => item.price === +inputPrice);
    return result ? result.type : "";
  };

  const [validPromoCodeValue, setValidePromoCodeValue] = useState(1000);
  const [isShowError, setIsShowError] = useState(false);
  const [errors, setErrors] = useState("");
  const [ihavePromoCode, setIhavePromoCode] = useState(false);
  const [createQrCode, setCreateQrCode] = useState(false);
  // /panier
  const [cart, setCart] = useState({
    typeTicket: "",
    qte: 1,
    promoCode: "",
  });
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    typeTransaction: "",
    phone: "",
  });
  /**
   * HANDLE ACTIONS
   */
  const handleSubmitStep = (step) => {
    console.log(cart, currentUser, step);
    setCreateQrCode(false);
    switch (step) {
      case 1:
        if (cart.typeTicket === "" || cart.qte === "") {
          setIsShowError(true);
          setErrors("Veuillez verifier le tarif et la quantité!");
          return;
        }
        goToNext();
        break;
      // STEP 2
      case 2:
        if (
          currentUser.firstName === "" ||
          currentUser.lastName === "" ||
          currentUser.email === "" ||
          currentUser.phone === "" ||
          currentUser.typeTransaction === ""
        ) {
          setIsShowError(true);
          setErrors("Veuillez renseigner tous les champs!");
          return;
        }
        goToNext();
        setCreateQrCode(true);
        break;

      default:
        break;
    }
    setIsShowError(false);
  };

  /**
   * STEPPER
   */
  const goToNext = () => {
    if (stepperInstance) {
      stepperInstance.next();
    }
  };

  const goToPrevious = () => {
    if (stepperInstance) {
      stepperInstance.previous();
    }
  };
  /**
   * CANVAS
   */
  const canvasOverlayRef = useRef();

  // Trigger download from the parent component
  const [isLoadindDownload, setIsLoadingDownload] = useState(false);
  const handleDownloadTicket = async () => {
    if (canvasOverlayRef.current) {
      setIsLoadingDownload(true);
      await canvasOverlayRef.current.downloadImage();
      setIsLoadingDownload(false);
    }
  };

  useEffect(() => {
    // Créer une instance du Stepper
    const instance = new Stepper(stepperRef.current, {
      linear: true,
    });

    setStepperInstance(instance);

    // Appeler to(1) uniquement si ce n'est pas encore initialisé
    if (!isInitialized) {
      instance.to(1);
      setIsInitialized(true); // Mettre à jour l'état pour indiquer que l'initialisation est faite
    }
    setCart({ ...cart, typeTicket: plan });

    // Cleanup (facultatif, mais utile)
    return () => {
      if (stepperInstance) {
        setStepperInstance(null);
      }
    };
  }, [isInitialized, plan]); // Ajoute isInitialized comme dépendance

  return (
    <div
      id="byTicket"
      className="col-lg-6 align-self-center px-4"
      data-aos="fade-up"
    >
      <div className="faq-form bg-white rounded shadow-lg p-6 mb-mi">
        <div className="form-title mb-4">
          <p className="mb-1 pink">TICKET</p>
          <h2 className="mb-2">
            ACHAT DE <span className="pink">TICKET</span>
          </h2>
          <p className="m-0">
            Sélectionnez vos billets, identifiez-vous, payez et téléchargez vos
            billets
          </p>
        </div>
        <div className="form-content justify-content-between">
          <div id="stepper2" ref={stepperRef} className="bs-stepper gap-0">
            <div className="card border-0">
              <div className="card-header p-0 bg-transparent border-0">
                {/* className="d-lg-flex flex-lg-row align-items-center justify-content-between" */}
                <div
                  className="d-flex align-items-center justify-content-between"
                  role="tablist"
                >
                  <div className="step rounded-circle" data-target="#test-nl-1">
                    <div
                      className="step-trigger"
                      role="tab"
                      id="stepper2trigger1"
                      aria-controls="test-nl-1"
                    >
                      <div className="">
                        <h6 className="mb-0 steper-title">1</h6>
                      </div>
                    </div>
                  </div>

                  <div className="bs-stepper-line"></div>

                  <div className="step" data-target="#test-nl-2">
                    <div
                      className="step-trigger"
                      role="tab"
                      id="stepper2trigger2"
                      aria-controls="test-nl-2"
                    >
                      <div className="">
                        <small className="mb-0 steper-title">2</small>
                      </div>
                    </div>
                  </div>

                  <div className="bs-stepper-line"></div>

                  <div className="step" data-target="#test-nl-3">
                    <div
                      className="step-trigger"
                      role="tab"
                      id="stepper2trigger3"
                      aria-controls="test-nl-3"
                    >
                      <div className="">
                        <small className="mb-0 steper-title">3</small>
                      </div>
                    </div>
                  </div>

                  <div className="bs-stepper-line"></div>

                  <div className="step" data-target="#test-nl-4">
                    <div
                      className="step-trigger"
                      role="tab"
                      id="stepper2trigger4"
                      aria-controls="test-nl-4"
                    >
                      <div className="">
                        <small className="mb-0 steper-title">4</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body px-0">
                <div className="bs-stepper-content p-0">
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* FIRTST =========================== */}
                    <div
                      id="test-nl-1"
                      role="tabpanel"
                      className="bs-stepper-pane"
                      aria-labelledby="stepper2trigger1"
                    >
                      <h5 className="mb-1">Panier</h5>
                      <p className="mb-4">Choisisez vos tickets</p>
                      <div className="row g-1 mb-3">
                        <div className="">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Tarif</th>
                                <th scope="col">Prix TTC</th>
                                <th scope="col">Quantité</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div>
                                    <select
                                      name="typeTicket"
                                      id="typeTicket"
                                      className="form-control form-control-sm"
                                      value={cart.typeTicket}
                                      onChange={(e) =>
                                        setCart({
                                          ...cart,
                                          typeTicket: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="" unselectable="on">
                                        -- Type --
                                      </option>
                                      {dbValues.map((item, key) => (
                                        <option key={key} value={item.price}>
                                          {item.type}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  {(
                                    +cart.qte * +cart.typeTicket
                                  ).toLocaleString()}
                                </td>
                                <td>
                                  <select
                                    name=""
                                    className="form-control form-control-sm"
                                    id=""
                                    value={cart.qte}
                                    onChange={(e) =>
                                      setCart({
                                        ...cart,
                                        qte: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="1" defaultChecked>
                                      1
                                    </option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <th>TOTAL (TTC)</th>
                                <td className="text-bold">
                                  {(
                                    +cart.qte * +cart.typeTicket
                                  ).toLocaleString()}{" "}
                                  XOF
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                          <div className="col-12 d-flex-justify-content-end mb-3">
                            <span className="text-bold">
                              Vous avez un code de réduction ?{" "}
                              <Link
                                to="#"
                                onClick={() =>
                                  setIhavePromoCode(!ihavePromoCode)
                                }
                                className="text-success"
                              >
                                Cliquez ici
                              </Link>
                            </span>
                          </div>
                          {ihavePromoCode && (
                            <div className="animate-top">
                              <div className="col-12">
                                <div className="input-group has-validation">
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                  >
                                    AW-
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="codePromos"
                                    aria-describedby="inputGroupPrepend"
                                    placeholder="000000"
                                    onChange={(e) =>
                                      setCart({
                                        ...cart,
                                        promoCode: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          onClick={() => handleSubmitStep(1)}
                          className="btn-ndfranz"
                          type="button"
                        >
                          Suivant
                          <i className="fa fa-arrow-right ms-2"></i>
                        </button>
                      </div>
                      {/* <!---end row--> */}
                    </div>

                    {/* SECOND =========================== */}
                    <div
                      id="test-nl-2"
                      role="tabpanel"
                      className="bs-stepper-pane"
                      aria-labelledby="stepper2trigger2"
                    >
                      <div className="">
                        <h5 className="mb-1">Informatin de facturation</h5>
                        <p className="mb-4">
                          Vous recevrez vos billets en pièce jointes à votre
                          adresse Email.
                        </p>
                      </div>

                      <div className="row g-3">
                        <div className="col-12">
                          <label htmlFor="firstName" className="form-label">
                            Nom
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="Ex: FERGANY"
                            value={currentUser.firstName}
                            onChange={(e) =>
                              setCurrentUser({
                                ...currentUser,
                                firstName: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12">
                          <label htmlFor="lastName" className="form-label">
                            Prénom
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="Ex: Maxime"
                            value={currentUser.lastName}
                            onChange={(e) =>
                              setCurrentUser({
                                ...currentUser,
                                lastName: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            placeholder="Ex: votremail@gmail.com"
                            value={currentUser.email}
                            onChange={(e) =>
                              setCurrentUser({
                                ...currentUser,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12">
                          <label htmlFor="email" className="form-label">
                            Payer par
                          </label>
                          <br />
                          <div className="col-12 d-flex gap-1">
                            <input
                              type="radio"
                              name="typeTransaction"
                              id="tmoney"
                              value="tmoney"
                              onChange={(e) =>
                                setCurrentUser({
                                  ...currentUser,
                                  typeTransaction: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="tmoney">TMoney</label>
                          </div>

                          <div className="col-12 d-flex gap-1">
                            <input
                              type="radio"
                              name="typeTransaction"
                              id="flooz"
                              value="flooz"
                              onChange={(e) =>
                                setCurrentUser({
                                  ...currentUser,
                                  typeTransaction: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="flooz">Flooz</label>
                          </div>
                        </div>

                        <div className="col-12">
                          <label
                            htmlFor="numResponssableField"
                            className="form-label"
                          >
                            Numéro de téléphone {currentUser.typeTransaction}
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              228
                            </span>
                            <input
                              type="number"
                              className="form-control"
                              id="validationCustomUsername"
                              aria-describedby="inputGroupPrepend"
                              required
                              value={currentUser.phone}
                              onChange={(e) =>
                                setCurrentUser({
                                  ...currentUser,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* PAGE OPTIONS */}
                        <div className="col-12 mt-5">
                          <div className="d-flex justify-content-between">
                            <button
                              onClick={goToPrevious}
                              className="btn-gay-ndfranz"
                              type="button"
                            >
                              <i className="fa fa-arrow-left me-2"></i>
                              Précedent
                            </button>

                            <button
                              onClick={() => handleSubmitStep(2)}
                              className="btn-ndfranz"
                              type="button"
                            >
                              Suivant
                              <i className="fa fa-arrow-right ms-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* TIRD =========================== */}
                    <div
                      id="test-nl-3"
                      role="tabpanel"
                      className="bs-stepper-pane"
                      aria-labelledby="stepper2trigger3"
                    >
                      <h5 className="mb-1">Payement</h5>
                      <p className="mb-4">
                        Verifiez vos informations et validez le payement
                      </p>

                      <div className="row g-3">
                        <div className="col-12">
                          <div className="col-12 d-flex gap-1 mb-2">
                            <span>Nom</span> : <h6>{currentUser.firstName}</h6>
                          </div>
                          <div className="col-12 d-flex gap-1 mb-2">
                            <span>Prénom</span> :{" "}
                            <h6>{currentUser.lastName}</h6>
                          </div>
                          <div className="col-12 d-flex gap-1 mb-2">
                            <span>Email</span> :{" "}
                            <h6 className="text-lowercase">
                              {currentUser.email}
                            </h6>
                          </div>
                          <div className="col-12 d-flex gap-1 mb-5">
                            <span>Numéro {currentUser.typeTransaction}</span> :{" "}
                            <h6 className="text-lowercase">
                              {currentUser.phone}
                            </h6>
                          </div>
                          <div className="">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Tarif</th>
                                  <th scope="col">Prix TTC</th>
                                  <th scope="col">Quantité</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{findType(cart.typeTicket)}</td>
                                  <td>
                                    {(
                                      +cart.qte * +cart.typeTicket
                                    ).toLocaleString()}
                                  </td>
                                  <td>{cart.qte}</td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th>TOTAL</th>
                                  <td className="text-bold">
                                    {(
                                      +cart.qte * +cart.typeTicket
                                    ).toLocaleString()}{" "}
                                    XOF
                                  </td>
                                </tr>
                                <tr>
                                  <th>REDUCTION VALIDE</th>
                                  <td>{validPromoCodeValue}</td>
                                </tr>
                                <tr>
                                  <th className="text-uppercase">
                                    TOTAL (TTC) à payer
                                  </th>
                                  <td>
                                    {(
                                      +cart.qte * +cart.typeTicket -
                                      validPromoCodeValue
                                    ).toLocaleString()}{" "}
                                    XOF
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <button
                              onClick={goToPrevious}
                              className="btn-gay-ndfranz"
                              type="button"
                            >
                              <i className="fa fa-arrow-left me-2"></i>
                              Précedent
                            </button>

                            <button
                              onClick={goToNext}
                              className="btn-ndfranz btn-sm"
                              type="button"
                            >
                              Valider
                              <i className="fa fa-arrow-right ms-2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <!---end row--> */}
                    </div>

                    {/* LAST =========================== */}
                    <div
                      id="test-nl-4"
                      role="tabpanel"
                      className="bs-stepper-pane"
                      aria-labelledby="stepper2trigger4"
                    >
                      <h5 className="mb-1">ETAT DE VOTRE COMMANDE</h5>
                      <p className="mb-4">{"COMMANDE STATE"}</p>

                      <div className="row g-3">
                        <div className="col-12 col-lg-12 d-flex justify-content-center">
                          {createQrCode && (
                            <CanvasImageOverlay
                              ref={canvasOverlayRef}
                              Medias={Medias}
                              TypeQrCode={findType(cart.typeTicket)}
                            />
                          )}
                        </div>

                        <div className="col-12">
                          <div className="d-flex align-items-center justify-content-between col-12">
                            <button
                              onClick={goToPrevious}
                              className="btn-gay-ndfranz px-4 btn-sm"
                              type="button"
                            >
                              <i className="fa fa-arrow-left me-2"></i>
                              Précedent
                            </button>
                            <button
                              type="submit"
                              className="btn-ndfranz px-4 btn-sm"
                              disabled={isLoadindDownload}
                              onClick={handleDownloadTicket}
                            >
                              <i className="fa fa-save"></i> Enregistrer tout
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <!---end row--> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* MESSAGES */}
          {isShowError && (
            <div className="col-12 mb-4 animate-left">
              <span className="badge bg-danger rounded px-3 py-2">
                {errors}
              </span>
            </div>
          )}
          {/* MESSAGES */}
          <small>
            Paiement simplifié et sécurisé par CinetPay. Une expérience d'achat
            sans tracas.
          </small>
        </div>
      </div>
    </div>
  );
}
