import React from "react";
import { Link } from "react-router-dom";
import { CountdownTimer } from "../app/inc/CountDownTimer";

export default function Header() {
  return (
    <header className="main_header_area position-absolute w-100">
      <div className="header-content text-white">
        <div className="container">
          <div className="header-content-inner py-2">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="social-links">
                  <ul className="m-0 p-0">
                    <li className="d-inline">
                      <Link
                        to="https://www.facebook.com/allwhitelome"
                        target="_blank"
                      >
                        <i className="fa fa-facebook border-social rounded-circle text-center"></i>
                      </Link>
                    </li>
                    <li className="d-inline">
                      <Link to="#" target="_blank">
                        <i className="fa fa-instagram border-social rounded-circle text-center"></i>
                      </Link>
                    </li>
                    <li className="d-inline">
                      <Link to="#" target="_blank">
                        <i className="fa fa-youtube-play border-social rounded-circle text-center"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 header-count">
                <CountdownTimer isHeader={true}/>
              </div>
              <div className="col-lg-4">
                <div className="header-event-info text-end">
                  <ul className="m-0 p-0">
                    <li className="px-2 border-end border-lightgrey border-opacity-50 d-inline">
                      <i className="fa fa-phone pe-1"></i>
                      <small>+228 96 00 33 38</small>
                    </li>
                    <li className="px-2 d-inline">
                      <i className="fa fa-envelope-o pe-1"></i>
                      <small>
                        <Link
                          to="mailto:allwhitepartylome@gmail.com"
                          className="__cf_email__"
                          data-cfemail="99f0f7fff6d9eefcfbeaf0edfcb7faf6f4"
                        >
                          [email&#160;protégé]
                        </Link>
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
