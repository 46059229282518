import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";

function App() {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={route.element}
          Component={route.Component}
        />
      ))}
    </Routes>
  );
}

export default App;
