import { useState, useEffect } from "react";

export default function GetUserLocationAndRoute() {
  const [locationPermission, setLocationPermission] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Vérifier si l'utilisateur utilise un appareil mobile
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            setLocationPermission("granted");
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "prompt") {
            setLocationPermission("prompt");
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "denied") {
            setLocationPermission("denied");
            if (isMobile) {
              // Si on est sur un appareil mobile, essayer d'ouvrir les paramètres
              alert("Veuillez activer la géolocalisation dans les paramètres de votre navigateur.");
              // Pour Google Chrome sur mobile, l'utilisateur peut être redirigé vers les paramètres
              window.open("chrome://settings/content/location", "_blank");
            } else {
              alert("Vous avez refusé l'accès à la géolocalisation. Veuillez l'activer dans vos paramètres.");
            }
          }
        })
        .catch(() => {
          alert("Impossible de vérifier l'état des permissions de géolocalisation.");
        });
    } else {
      alert("La géolocalisation n'est pas supportée par votre navigateur.");
    }
  };

  function success(position) {
    const userLat = position.coords.latitude;
    const userLng = position.coords.longitude;

    // Coordonnées de la destination WINNER BEACH
    const targetLat = 6.1316603;
    const targetLng = 1.2507396;

    const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${targetLat},${targetLng}&travelmode=driving`;
    window.open(mapsUrl, "_blank");
  }

  function error(err) {
    alert(`Impossible d'obtenir votre position. Erreur : ${err.message}`);
  }

  return (
    <>
      <button onClick={getUserLocation} className="btn btn1">
        <i className="fa fa-map-pin"></i> Obtenir l'itinéraire
      </button>
      {locationPermission === "denied" && (
        <p style={{ color: "red", marginTop: "10px" }}>
          Accès à la géolocalisation refusé. Veuillez l'activer dans vos paramètres.
        </p>
      )}
    </>
  );
}
