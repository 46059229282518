import React from "react";
import { Link } from "react-router-dom";
import Medias from "../inc/Medias";

export default function Footer() {
  return (
    <footer className="pt-3 text-center text-white position-relative z-1">
      <div className="overlay z-n1 start-0"></div>
      <div className="container">
        <div className="footer-content w-lg-50 m-auto">
          <div className="footer-logo mb-4 pt-1">
            <Link to="/">
              <img src={Medias.logo} className="w-50" alt="footer-logo" />
            </Link>
          </div>
          <div className="footer-disciption border-bottom border-white border-opacity-25 m-auto mb-6">
            <p className="mb-6">
              Votre paiement est sécurisé par CnetPay. Profitez d'une expérience
              d'achat en toute confiance.
            </p>
            <div className="footer-socials pb-6">
              <ul className="m-0 p-0">
                <li className="d-inline me-2">
                  <Link
                    to="#"
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li className="d-inline me-2">
                  <Link
                    to="#"
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="fa fa-instagram"></i>
                  </Link>
                </li>
                <li className="d-inline me-2">
                  <Link
                    to="#"
                    className="d-inline-block rounded-circle bg-white bg-opacity-25"
                  >
                    <i className="fa fa-youtube-play"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright pb-6 pt-1">
          <small>
            Copyright &#169; 2024
            <Link to="https://www.linkedin.com/in/ndfranz" target="_blank">
              {" "}
              NDFranz
            </Link>
            . All Rights Reserved Copyright
          </small>
        </div>
      </div>
    </footer>
  );
}
