import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import BackToTop from "../layouts/BackToTop";
import Medias from "../inc/Medias";
import { Link } from "react-router-dom";
import FormTicket from "./inc/FormTicket";
import { Link as ScrollLink } from "react-scroll";
import { CountdownTimer } from "./inc/CountDownTimer";
import GetUserLocationAndRoute from "./inc/GetUserLocationAndRoute";

export default function Home() {
  // default action on the page
  const [plan, setPlan] = useState("");

  // INIT default Plugins
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }, []);

  // RETURN THE APP
  return (
    <>
      {/* HEADER -------------------------*/}
      <Header />
      {/* HEADER */}
      {/* BANNER ------------------------ */}
      <section
        id="top"
        className="banner position-relative pb-0 banner-mob section-mob"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="inner-banner position-relative text-white inner-banner-mob">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="banner-left text-center pb-lg-5 p-md-0">
                  <div className="banner-image" data-aos="fade-right">
                    <img
                      src={Medias.bannerImage}
                      alt="banner-image"
                      className="w-75"
                    />
                    <br />
                  </div>
                  <div className="countdown">
                    <CountdownTimer isHeader={false} />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 resp-banner"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <div className="banner-right ms-2 text-center text-lg-start pb-8">
                  <div className="text-sm-center d-md-none d-lg-none d-flex justify-content-center mt-3">
                    <CountdownTimer isHeader={true} />
                  </div>
                  <div className="idition text-sm-center">
                    <img src={Medias.logo} alt="edition-logo" />
                  </div>
                  <div className="banner-title pb-3">
                    <h4 className="text-white pb-3">
                      LA SIXIEME <span className="pink">EDITION</span> EN 2024
                    </h4>
                    <h1 className="text-white">
                      ALL <span className="pink">WHITE</span> LOME
                    </h1>
                  </div>
                  <div className="banner-event-info pb-3">
                    <ul className="m-0 ps-0 d-sm-flex justify-content-center justify-content-lg-start list-unstyled">
                      <li className="pe-2 border-end border-1 border-lightgrey add-right">
                        <i className="fa fa-calendar-o pe-1"></i> 24-DEC 2024
                      </li>
                      <li className="ps-2 add-left">
                        <i className="fa fa-map-marker pe-1"></i> WINNER BEACH
                      </li>
                    </ul>
                  </div>
                  <div className="event-discription">
                    <p className="pb-4 m-0">
                      Bienvenue au paradis blanc d'All White Lomé 6.
                      Laissez-vous séduire par l'accueil chaleureux de nos
                      hôtesses, prêtes à vous faire vivre une soirée inoubliable
                      sous les étoiles.
                    </p>
                    <div className="banner-button">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <ScrollLink
                            className="btn me-3 my-1 w-100"
                            to="byTicket"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            ACHETER MON TICKET
                          </ScrollLink>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <ScrollLink
                            className="btn btn2 me-3 my-1 w-100"
                            to="map"
                            spy={true}
                            smooth={true}
                            duration={200}
                          >
                            <i className="fa fa-map-pin"></i> Obtenir
                            l'itinéraire
                          </ScrollLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wave overflow-hidden position-absolute w-100 z-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
            className="d-block position-relative"
          >
            <path
              className="elementor-shape-fill"
              d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
          c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
          c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
            ></path>
          </svg>
        </div>
      </section>

      {/* OVERVIEW ---------------------- */}
      <section className="overview pb-0">
        <div className="container">
          <div className="inner-overview position-relative border-dashed-bottom-2 nfranz-padding">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="overview-left text-center text-lg-start"
                  data-aos="fade-right"
                >
                  <div className="overview-title pb-4">
                    <p className="mb-1 pink">APERÇU</p>
                    <h2 className="mb-2">
                      OBTENEZ LES DERNIÈRES INFOS SUR
                      <span className="pink">ALL WHITE</span>
                    </h2>
                    <p>
                      All White Lomé 6 : La soirée qui vous fera briller de
                      mille feux. Laissez-vous emporter par la magie de cette
                      nuit exceptionnelle. Musique, danse, et surprises vous
                      attendent. Prêt à vivre une expérience unique ? Découvrez
                      vite ce qui vous réserve !
                    </p>
                    <p className="mb-2">
                      Blanc comme neige, chaud comme le feu.
                    </p>
                  </div>
                  <div className="overview-event-info pb-6 g-4 text-start position-absolute">
                    <div className="row justify-content-around">
                      <div className="col-lg-6 col-md-6">
                        <Link to="map">
                          <div className="event-info-box align-items-center d-flex p-4 rounded bg-white box-shadow my-2">
                            <div className="event-info-icon text-center">
                              <i className="fa fa-map-marker text-white bg-pink rounded-circle me-3"></i>
                            </div>
                            <div className="location-info">
                              <h5 className="mb-1 text-capitalise">Où</h5>
                              <small className="text-capitalises text-secondary">
                                WINNER BEACH Lomé
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <a>
                          <div className="event-info-box align-items-center d-flex p-4 rounded bg-white box-shadow my-2">
                            <div className="event-info-icon text-center">
                              <i className="fa fa-calendar-o text-white bg-pink rounded-circle me-3"></i>
                            </div>
                            <div className="time-info">
                              <h5 className="mb-1 text-capitalise">QUAND</h5>
                              <small>
                                Dimanche
                                <br />
                                le 15 Dec, 2024 à partir de 12h:00
                              </small>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="d-flex gap-3 col-12 justify-content-end">
                        <div className="d-md-flex gap-3 col-12 py-3 justify-content-end">
                          <figure className="auhtor-signature">
                            <img
                              src={Medias.bloggable}
                              alt=""
                              style={{ width: "142px" }}
                            />
                          </figure>
                          <div className="author-detail">
                            <h6 className="author-name">MAXIME FERGANY</h6>
                            <span className="author-prof">Organisateur</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="overview-img">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 p-0">
                      <div className="container-img-left mb-2">
                        <div
                          className="img-left-1 float-end w-lg-80"
                          data-aos="zoom-in"
                        >
                          <img
                            className="mb-2 w-100 rounded"
                            src={Medias.group01}
                            alt="group-image"
                          />
                        </div>
                        <div
                          className="img-left-2"
                          data-aos="zoom-in"
                          data-aos-delay="200"
                        >
                          <img
                            src={Medias.group02}
                            alt="group-image"
                            className="w-100 rounded"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-6 col-md-6"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="container-img-right w-lg-75">
                        <img
                          src={Medias.group03}
                          alt="group-image"
                          className="w-100 rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* PARTENERS --------------------- */}
      <section className="partners">
        <div className="container">
          <div className="partner-inner">
            <div
              className="partner-title text-center pb-6 w-lg-60 m-auto"
              data-aos="fade-down"
            >
              <p className="mb-1 pink">NOS PARTENAIRES</p>
              <h2 className="mb-1">
                NOS <span className="pink">PARTENAIRES</span>
              </h2>
              <p className="m-0">
                Plus que des partenaires, ce sont des collaborateurs avec qui
                nous partageons les mêmes valeurs.
              </p>
            </div>
            <div className="partner-img pb-6" data-aos="fade-up">
              <div className="row row-cols-1 row-cols-lg-5 row-cols-md-5 justify-content-center">
                <div className="col p-0">
                  <div className="p-2 partner-img-box text-center">
                    <img
                      src={Medias.partener01}
                      alt="partner-img"
                      className="opacity-75 w-lg-75 w-md-100 w-40"
                    />
                  </div>
                </div>
                {/* <div className="col p-0">
                  <div className="p-2 partner-img-box text-center">
                    <img
                      src={Medias.partener02}
                      alt="partner-img"
                      className="opacity-75 w-lg-75 w-md-100 w-40"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ticket pub -------------------------*/}
      <section className="ticket position-relative">
        <div className="overlay"></div>
        <div className="container" data-aos="zoom-in">
          <div className="ticket-inner w-lg-75 mx-auto text-center position-relative text-white">
            <div className="ticket-title">
              <h5 className="text-white mb-1">FAISONS-LE VITE</h5>
              <h1 className="text-white mb-2">
                VOUS N'AVEZ PAS ENCORE RÉSERVÉ VOTRE PLACE ?
              </h1>
              <br />
            </div>
            <div className="ticket-info">
              <p>
                Imaginez-vous plongé(e) au cœur de l'action... <br />
                Réservez votre place dès maintenant et profitez de souvenirs
                inoubliables.
              </p>
              <div className="ticket-button">
                <ScrollLink
                  className="btn btn1"
                  to="byTicket"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  ACHETER MON TICKET
                </ScrollLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Price -------------------------*/}
      <section className="price bg-lightgrey">
        <div className="container">
          <div className="price-inner text-center">
            <div
              className="price-title mb-7 w-lg-60 m-auto"
              data-aos="fade-down"
            >
              <p className="mb-1 pink">TABLEAU DES PRIX</p>
              <h2 className="mb-1">
                EXPLOREZ LES PLANS <span className="pink">TARIFAIRES</span>{" "}
                FLEXIBLES
              </h2>
              <p className="m-0">
                Nos plans s'adaptent à votre rythme. Payez seulement pour ce
                dont vous avez besoin.
              </p>
            </div>
            <div className="price-options g-2 pb-6">
              <div className="row">
                {/* plan 1 */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="ticket-box">
                    <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                      <img
                        src={Medias.pattern01}
                        alt="pattern"
                        className="w-100 pattern position-absolute rounded-top"
                      />
                      <div className="ticket-title">
                        <h4 className="pt-6 mb-1">SIMPLE</h4>
                        <p>solo</p>
                      </div>
                      <div className="ticket-price bg-white py-6">
                        <div className="ticket-price-inner d-flex justify-content-center align-items-start black fw-bold">
                          <span className="me-1 m-0 dollor">15 000</span>
                          <span className="me-1 mb-0 cents">F</span>
                        </div>
                        <p className="m-0">Par personne</p>
                      </div>
                      <div className="ticket-button m-auto p-6">
                        <ScrollLink
                          className="btn"
                          to="byTicket"
                          spy={true}
                          smooth={true}
                          duration={200}
                          onClick={() => setPlan("15000")}
                        >
                          Choisir
                        </ScrollLink>
                        <p className="mt-4">
                          <small>Viens vivre l'expérience !</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Plan 2 */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                    <img
                      src={Medias.pattern02}
                      alt="pattern"
                      className="w-100 pattern position-absolute rounded-top"
                    />
                    <div className="ticket-title py-6 bg-pink text-white">
                      <h4 className="text-white mb-1">COUPLE</h4>
                      <p>Par deux</p>
                    </div>
                    <div className="ticket-price bg-white py-6">
                      <div className="ticket-price-inner d-flex justify-content-center align-items-start fw-bold">
                        <span className="me-1 m-0 dollor pink">28 000</span>
                        <span className="me-1 mb-0 cents pink">F</span>
                      </div>
                      <p className="m-0">Par couple</p>
                    </div>
                    <div className="partner-button m-auto p-6">
                      <ScrollLink
                        className="btn"
                        to="byTicket"
                        spy={true}
                        smooth={true}
                        duration={200}
                        onClick={() => setPlan("28000")}
                      >
                        Choisir
                      </ScrollLink>
                      <p className="mt-4">
                        <small>Profitez d'une escapade en amoureux !</small>
                      </p>
                    </div>
                  </div>
                </div>
                {/* plan 3 */}
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="ticket-box-inner bg-white position-relative rounded-bottom-5">
                    <img
                      src={Medias.pattern01}
                      alt="pattern"
                      className="w-100 pattern position-absolute rounded-top"
                    />
                    <div className="ticket-title">
                      <h4 className="pt-6 mb-1">GROUPE</h4>
                      <p>Par groupe de 8 personne max</p>
                    </div>
                    <div className="ticket-price bg-white py-6">
                      <div className="ticket-price-inner d-flex justify-content-center align-items-start black fw-bold">
                        <span className="me-1 m-0 dollor">112 000</span>
                        <span className="me-1 mb-0 cents">F</span>
                      </div>
                      <p className="m-0">8 personnes</p>
                    </div>
                    <div className="partner-button m-auto p-5">
                      <ScrollLink
                        className="btn"
                        to="byTicket"
                        spy={true}
                        smooth={true}
                        duration={200}
                        onClick={() => setPlan("112000")}
                      >
                        Choisir
                      </ScrollLink>
                      <p className="mt-4">
                        <small>Vivez un moment privilégié</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TICKET BUY -------------------------*/}
      <section className="faq pb-0 overflow-visible d-block position-relative z-1">
        <div className="overlay bg-blue opacity-25 z-n1"></div>
        <div className="container">
          <div>
            <div className="row">
              <div
                className="col-lg-6 px-4 mb-2"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div className="faq-general">
                  <div className="section-title pb-3 text-center text-lg-start">
                    <p className="mb-1 pink">QUESTIONS GÉNÉRALES</p>
                    <h2 className="mb-2 text-white">
                      FOIRE AUX <span className="pink">QUESTIONS!!</span>  !!
                    </h2>
                    <p className="text-white">
                      Vous avez des questions ? Nous avons les réponses !
                    </p>
                  </div>
                  <div className="faq-accordion p-4 mb-5 bg-white rounded">
                    <div
                      className="accordion accordion-faq"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <p className="accordion-header p-4">
                          <button
                            className="accordion-button collapsed fw-semibold p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            Quels sont les tarifs pour un individu, un couple et
                            un groupe ?
                          </button>
                        </p>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body bg-lightgrey p-6">
                            Le tarif est de 15 000 pour un individu, 28 000 pour
                            un couple et 112 000 pour un groupe
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <p className="accordion-header p-4">
                          <button
                            className="accordion-button collapsed fw-semibold p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Qu'est-ce qui est considéré comme un "groupe" ?
                          </button>
                        </p>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body bg-lightgrey p-6">
                            Un groupe est généralement constitué de 8 personnes
                            ou plus. N'hésitez pas à nous contacter pour toute
                            demande spécifique
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <p className="accordion-header p-4">
                          <button
                            className="accordion-button collapsed fw-semibold p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Quels sont les modes de paiement acceptés ?
                          </button>
                        </p>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body bg-lightgrey p-6">
                            Nous acceptons les paiements par [liste des modes de
                            paiement :
                            <ul>
                              <li>Flooz</li>
                              <li>Tmoney</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* form ticket */}
              <FormTicket plan={plan} setPlan={setPlan} />
              {/* /form ticket */}
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER -------------------------*/}
      <section className="direction pb-5" id="map">
        <div className="container">
          <div className="section-title text-center pb-2 w-lg-60 m-auto">
            <p className="mb-1 pink">REJOIGNEZ-NOUS</p>
            <h2 className="mb-1">
              OBTENIR L'ITINÉRAIRE VERS{" "}
              <span className="pink">L'ÉVÉNEMENT</span>
            </h2>
            <p className="mb-0">
              Quelques clics te séparent de l'événement. Rejoins-nous !
            </p>
          </div>
          <div className="direction-content">
            <div className="direction-info">
              <div className="row">
                <div
                  className="col-lg-5 col-md-5 px-1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="p-6 d-flex text-white w-100 h-100 rounded bg-pink">
                    <div className="justify-content-center align-self-center ms-6">
                      <h5 className="text-white pb-2">LIEU DE L'ÉVÉNEMENT :</h5>
                      <p className="pb-5 m-0">WINNER BEACH</p>
                      <h5 className="text-white pb-2">ADDRESS:</h5>
                      <p className="pb-5 m-0">
                        47J2+XCM, Boulevard Du Mono, Lomé
                      </p>
                      <h5 className="text-white pb-2">PLUS D'INFO:</h5>
                      <p className="pb-5 m-0">+228 96 00 33 38</p>
                      <GetUserLocationAndRoute />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 px-1 py-2">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.158095747257!2d1.2507396!3d6.131660299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1023e1ed6be1e56d%3A0x8c6389358b28bd63!2sWINNER%20BEACH!5e1!3m2!1sen!2stg!4v1727456477678!5m2!1sen!2stg"
                    height="400"
                    className="rounded w-100"
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER -------------------------*/}
      <Footer />
      {/* FOOTER */}
      {/* BACK TO TOP --------------------*/}
      <BackToTop />
      {/* BACK TO TOP */}
    </>
  );
}
