import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

const CanvasImageOverlay = forwardRef(({ Medias, TypeQrCode }, ref) => {
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(true); // Track loading status

  const ticket = [
    { type: "solo", data: Medias.solo },
    { type: "groupe", data: Medias.groupe },
    { type: "couple", data: Medias.couple },
  ];
  const TickeyToUse = ticket.filter((t) => t.type.toLowerCase() === TypeQrCode.toLowerCase());

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Load the base image (solo image)
    const baseImage = new Image();
    baseImage.src = TickeyToUse[0].data;
    baseImage.onload = () => {
      // Set canvas size to match the base image
      canvas.width = baseImage.width;
      canvas.height = baseImage.height;

      // Draw the base image
      context.drawImage(baseImage, 0, 0, canvas.width, canvas.height);

      // Load the QR code image
      const qrCodeImage = new Image();
      qrCodeImage.src = Medias.qrcode;

      qrCodeImage.onload = () => {
        // Calculate the position of the QR code as per your style
        const qrWidth = canvas.width * 0.8; // 80% of canvas width
        const qrHeight = qrCodeImage.height * (qrWidth / qrCodeImage.width); // Maintain aspect ratio
        const qrX = (canvas.width - qrWidth) / 2; // Centered horizontally
        const qrY = canvas.height * 0.92 - qrHeight; // Positioned bottom 15.5%

        // Draw the QR code image
        context.drawImage(qrCodeImage, qrX, qrY, qrWidth, qrHeight);

        // Image loading is complete
        setLoading(false);
      };
    };
  }, [Medias]);

  // Function to download the canvas as PNG
  const downloadImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download =
      "AWL6-" + Math.floor(Math.random() * 100000000).toString() + ".png"; // Default file name
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  // Expose the downloadImage function to the parent
  useImperativeHandle(ref, () => ({
    downloadImage,
  }));

  return (
    <div className="position-relative">
      {loading && (
        <>
          <div className="lds-heart">
            <div></div>
          </div>
        </>
      )}
      <canvas
        ref={canvasRef}
        style={{ width: "100%", display: loading ? "none" : "block" }}
      />
    </div>
  );
});

export default CanvasImageOverlay;
