import logo from "../assets/images/logo/004.png";
import bannerImage from "../assets/images/team/06.png";
import bannerImage02 from "../assets/images/team/07.png";
import bloggable from "../assets/images/group/bloggable-img14.png";
import group01 from "../assets/images/group/1.jpg";
import group02 from "../assets/images/group/045.jpg";
import group03 from "../assets/images/group/3.jpg";
import partener01 from "../assets/images/icon/1.png";
import partener02 from "../assets/images/icon/2.png";
import pattern01 from "../assets/images/pattern/1.png";
import pattern02 from "../assets/images/pattern/002.png";
import solo from "../assets/images/qrcode/solo.png";
import groupe from "../assets/images/qrcode/groupe.png";
import couple from "../assets/images/qrcode/couple.png";
import qrcode from "../assets/images/qrcode/qrcode.png";

const Medias = {
  logo,
  bannerImage,
  bannerImage02,
  bloggable,
  group01,
  group02,
  group03,
  partener01,
  partener02,
  pattern01,
  pattern02,
  solo,
  groupe,
  couple,
  qrcode,
};
export default Medias;
